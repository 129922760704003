/*
全局的表单验证方法
order by qinhaozhi Data:20170915
(Remember to write down each change)
*/

export default {
  isPhone(phone) {
    let newPhone = phone.replace(/\s/g, '')
    if (newPhone == null || newPhone == '' || newPhone == undefined) {
      return false
    } else if (newPhone.substr(0, 1) != 1) {
      return false
    } else if (newPhone.length != 11) {
      return false
    }
    return true
  },

  isPhone1: (phone) => /^1[3456789]\d{9}$/.test(phone),

  isLoginPassword(val, minLen, maxLen) {
    if (val == null || val == '' || val == undefined) {
      return false
    } else if (val.length < minLen || val.length > maxLen) {
      return false
    } else if (/^[\d]*$|[\s]+/.test(val)) {
      return false
    }
    return true
  },
  isNull(val) {
    if (val === '' || val == null || val == undefined || val == 'null') {
      return false
    }
    return true
  },
  isPayPassword(val) {
    if (val == '' || val == null || val == undefined) {
      return false
    } else if (val.length != 6) {
      return false
    } else if (/^\d{6}$/.test(val) == false) {
      return false
    } else if (/(\d)(\1)(\1)/.test(val) == true) {
      return false
    }
    return true
  },
  isBankCard(bankno) {
    if (!bankno || bankno.length < 5) {
      return false
    }
    bankno = bankno.replace(/\s+/g, '')
    var lastNum = bankno.substr(bankno.length - 1, 1)

    var first15Num = bankno.substr(0, bankno.length - 1)
    var newArr = new Array()
    for (var i = first15Num.length - 1; i > -1; i--) {
      newArr.push(first15Num.substr(i, 1))
    }
    var arrJiShu = new Array()
    var arrJiShu2 = new Array()

    var arrOuShu = new Array()
    for (var j = 0; j < newArr.length; j++) {
      if ((j + 1) % 2 == 1) {
        if (parseInt(newArr[j]) * 2 < 9) arrJiShu.push(parseInt(newArr[j]) * 2)
        else arrJiShu2.push(parseInt(newArr[j]) * 2)
      } else arrOuShu.push(newArr[j])
    }

    var jishu_child1 = new Array()
    var jishu_child2 = new Array()
    for (var h = 0; h < arrJiShu2.length; h++) {
      jishu_child1.push(parseInt(arrJiShu2[h]) % 10)
      jishu_child2.push(parseInt(arrJiShu2[h]) / 10)
    }

    var sumJiShu = 0
    var sumOuShu = 0
    var sumJiShuChild1 = 0
    var sumJiShuChild2 = 0
    var sumTotal = 0
    for (var m = 0; m < arrJiShu.length; m++) {
      sumJiShu = sumJiShu + parseInt(arrJiShu[m])
    }

    for (var n = 0; n < arrOuShu.length; n++) {
      sumOuShu = sumOuShu + parseInt(arrOuShu[n])
    }

    for (var p = 0; p < jishu_child1.length; p++) {
      sumJiShuChild1 = sumJiShuChild1 + parseInt(jishu_child1[p])
      sumJiShuChild2 = sumJiShuChild2 + parseInt(jishu_child2[p])
    }
    sumTotal =
      parseInt(sumJiShu) + parseInt(sumOuShu) + parseInt(sumJiShuChild1) + parseInt(sumJiShuChild2)

    var k = parseInt(sumTotal) % 10 == 0 ? 10 : parseInt(sumTotal) % 10
    var luhm = 10 - k

    if (lastNum == luhm) {
      return true
    } else {
      return false
    }
  },
  /*
  刘燕宇 2019/08/15 修改isIdCard方法，之前的身份证校验方法只校验19XX出生年份，20XX不通过
  */
  //身份证号合法性验证
  //支持15位和18位身份证号
  //支持地址编码、出生日期、校验位验证
  isIdCard(code) {
    var city = {
      11: '北京',
      12: '天津',
      13: '河北',
      14: '山西',
      15: '内蒙古',
      21: '辽宁',
      22: '吉林',
      23: '黑龙江 ',
      31: '上海',
      32: '江苏',
      33: '浙江',
      34: '安徽',
      35: '福建',
      36: '江西',
      37: '山东',
      41: '河南',
      42: '湖北 ',
      43: '湖南',
      44: '广东',
      45: '广西',
      46: '海南',
      50: '重庆',
      51: '四川',
      52: '贵州',
      53: '云南',
      54: '西藏 ',
      61: '陕西',
      62: '甘肃',
      63: '青海',
      64: '宁夏',
      65: '新疆',
      71: '台湾',
      81: '香港',
      82: '澳门',
      91: '国外 ',
    }
    var tip = ''
    var pass = true
    //验证身份证格式（6个地区编码，8位出生日期，3位顺序号，1位校验位）
    if (
      !code ||
      !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(code)
    ) {
      tip = '身份证号格式错误'
      pass = false
    } else if (!city[code.substr(0, 2)]) {
      tip = '地址编码错误'
      pass = false
    } else {
      //18位身份证需要验证最后一位校验位
      if (code.length == 18) {
        code = code.split('')
        //∑(ai×Wi)(mod 11)
        //加权因子
        var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
        //校验位
        var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2]
        var sum = 0
        var ai = 0
        var wi = 0
        for (var i = 0; i < 17; i++) {
          ai = code[i]
          wi = factor[i]
          sum += ai * wi
        }
        var last = parity[sum % 11]
        if (parity[sum % 11] != code[17]) {
          tip = '校验位错误'
          pass = false
        }
      }
    }

    return pass
  },
  // isIdCard(idcard) {
  //   var area = {
  //     11: "北京",
  //     12: "天津",
  //     13: "河北",
  //     14: "山西",
  //     15: "内蒙古",
  //     21: "辽宁",
  //     22: "吉林",
  //     23: "黑龙江",
  //     31: "上海",
  //     32: "江苏",
  //     33: "浙江",
  //     34: "安徽",
  //     35: "福建",
  //     36: "江西",
  //     37: "山东",
  //     41: "河南",
  //     42: "湖北",
  //     43: "湖南",
  //     44: "广东",
  //     45: "广西",
  //     46: "海南",
  //     50: "重庆",
  //     51: "四川",
  //     52: "贵州",
  //     53: "云南",
  //     54: "西藏",
  //     61: "陕西",
  //     62: "甘肃",
  //     63: "青海",
  //     64: "宁夏",
  //     65: "新疆",
  //     71: "台湾",
  //     81: "香港",
  //     82: "澳门",
  //     91: "国外"
  //   }
  //   if (!idcard) {
  //     return false
  //   }
  //   var retflag = false;
  //   var idcard, Y, JYM;
  //   var S, M;
  //   var idcard_array = new Array();
  //   var ereg;
  //   idcard_array = idcard.split("");
  //   //地区检验
  //   if (area[parseInt(idcard.substr(0, 2))] == null) return false;
  //   //身份号码位数及格式检验
  //   switch (idcard.length) {
  //     case 15:
  //       if ((parseInt(idcard.substr(6, 2)) + 1900) % 4 == 0 || ((parseInt(idcard.substr(6, 2)) + 1900) %
  //           100 == 0 && (parseInt(idcard.substr(6, 2)) + 1900) % 4 == 0)) {
  //         ereg = /^[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}$/; //测试出生日期的合法性
  //       } else {
  //         ereg = /^[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}$/; //测试出生日期的合法性
  //       }
  //       if (ereg.test(idcard))
  //         return true;
  //       else {
  //         return false;
  //       }
  //       break;
  //     case 18:
  //       //18位身份号码检测
  //       //出生日期的合法性检查
  //       //闰年月日:((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))
  //       //平年月日:((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))
  //       if (parseInt(idcard.substr(6, 4)) % 4 == 0 || (parseInt(idcard.substr(6, 4)) % 100 == 0 &&
  //           parseInt(idcard.substr(6, 4)) % 4 == 0)) {

  //         ereg = /^[1-9][0-9]{5}19[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}[0-9Xx]$/; //闰年出生日期的合法性正则表达式
  //       } else {

  //         ereg = /^[1-9][0-9]{5}19[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}[0-9Xx]$/; //平年出生日期的合法性正则表达式
  //       }
  //       if (ereg.test(idcard)) { //测试出生日期的合法性
  //         //计算校验位
  //         S = (parseInt(idcard_array[0]) + parseInt(idcard_array[10])) * 7 +
  //           (parseInt(idcard_array[1]) + parseInt(idcard_array[11])) * 9 +
  //           (parseInt(idcard_array[2]) + parseInt(idcard_array[12])) * 10 +
  //           (parseInt(idcard_array[3]) + parseInt(idcard_array[13])) * 5 +
  //           (parseInt(idcard_array[4]) + parseInt(idcard_array[14])) * 8 +
  //           (parseInt(idcard_array[5]) + parseInt(idcard_array[15])) * 4 +
  //           (parseInt(idcard_array[6]) + parseInt(idcard_array[16])) * 2 +
  //           parseInt(idcard_array[7]) * 1 +
  //           parseInt(idcard_array[8]) * 6 +
  //           parseInt(idcard_array[9]) * 3;
  //         Y = S % 11;
  //         M = "F";
  //         JYM = "10X98765432";
  //         M = JYM.substr(Y, 1); //判断校验位
  //         if (M == idcard_array[17])
  //           return true; //检测ID的校验位
  //         else
  //           return false;
  //       } else
  //         return false;

  //       break;
  //     default:
  //       return false;
  //       break;
  //   }
  // },
  //是否为邮箱
  isEmail(str) {
    if (!str) {
      return
    }
    str = str.trim()
    var reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/
    if (!reg.test(str)) {
      return false
    }
    return true
  },
  /*
  效验名族
  */
  isNation(str) {
    if (!str) {
      return false
    }
    var nationMap = [
      '汉族',
      '壮族',
      '满族',
      '回族',
      '苗族',
      '维吾尔族',
      '土家族',
      '彝族',
      '蒙古族',
      '藏族',
      '布依族',
      '侗族',
      '瑶族',
      '朝鲜族',
      '白族',
      '哈尼族',
      '哈萨克族',
      '黎族',
      '傣族',
      '畲族',
      '傈僳族',
      '仡佬族',
      '东乡族',
      '高山族',
      '拉祜族',
      '水族',
      '佤族',
      '纳西族',
      '羌族',
      '土族',
      '仫佬族',
      '锡伯族',
      '柯尔克孜族',
      '达斡尔族',
      '景颇族',
      '毛南族',
      '撒拉族',
      '塔吉克族',
      '阿昌族',
      '普米族',
      '鄂温克族',
      '怒族',
      '京族',
      '基诺族',
      '德昂族',
      '保安族',
      '俄罗斯族',
      '裕固族',
      '乌兹别克族',
      '门巴族',
      '鄂伦春族',
      '独龙族',
      '塔塔尔族',
      '赫哲族',
      '珞巴族',
      '布朗族',
    ]
    var r = new RegExp(str + ',')
    return r.test(nationMap)
  },
  /*
  检查省份
  */
  isProvince(str) {
    if (!str) {
      return false
    }
    if (str == '内蒙古' || str == '黑龙江') {
      return true
    } else {
      str = str.replace(/省/g, '').replace(/市/g, '')
    }
    var provinceMap = [
      '北京',
      '天津',
      '河北',
      '山西',
      '内蒙古',
      '辽宁',
      '吉林',
      '黑龙江',
      '上海',
      '江苏',
      '浙江',
      '安徽',
      '福建',
      '江西',
      '山东',
      '河南',
      '湖北',
      '湖南',
      '广东',
      '广西',
      '海南',
      '重庆',
      '四川',
      '贵州',
      '云南',
      '西藏',
      '陕西',
      '甘肃',
      '青海',
      '宁夏',
      '新疆',
    ]
    var r = new RegExp(str)
    return r.test(provinceMap)
  },
  /**
   * 效验密码强度
   * 入参：string 密码
   */
  checkPwdStrong: function (pwd) {
    var m = 0
    var Modes = 0
    for (let i = 0; i < pwd.length; i++) {
      var charType = 0
      var t = pwd.charCodeAt(i)
      if (t >= 48 && t <= 57) {
        charType = 1
      } //为0～9十个阿拉伯数字
      else if (t >= 65 && t <= 90) {
        charType = 2
      } //为26个大写英文字母
      else if (t >= 97 && t <= 122) {
        charType = 4
      } //为26个小写英文字母
      else {
        charType = 8
      }
      Modes |= charType
    }
    //计算密码模式
    for (let i = 0; i < 4; i++) {
      if (Modes & 1) {
        m++
      }
      Modes >>>= 1
    }
    if (pwd.length < 8 || pwd.length > 16) {
      m = 0
    }
    //返回强度级别
    return m
  },
  // 判断正整数
  iszzNumber: function (value) {
    var reg = /(^[1-9]\d*$)/
    if (value === undefined || value === null || value === '') {
      return false
    }
    if (!reg.test(value)) {
      return false
    }
    return true
  },
  // 判断整数保留两位小数
  istwoPoint: function (value) {
    var reg = /^((([1-9]{1}\d*)|([0]{1}))(\.(\d){0,2})?)$/
    if (value === undefined || value === null || value === '') {
      return false
    }
    if (!reg.test(value)) {
      return false
    }
    return true
  },
  isNumber: function (value) {
    if (value === undefined || value === null || value === '') {
      return false
    }

    if (typeof value === 'string') {
      //正整数
      var reNumber = /^\d+$/
      //负整数
      var reNeNumber = /^-\d+$/
      //正实数
      var reRealNumber1 = /^[1-9]\d*[.]\d+$/ //非零开头
      var reRealNumber2 = /^0[.]\d+$/ //零开头
      //负实数
      var reNeRealNumber1 = /^-[1-9]\d*[.]\d+$/ //非零开头
      var reNeRealNumber2 = /^-0[.]\d+$/ //零开头

      if (
        reNumber.test(value) ||
        reNeNumber.test(value) ||
        reRealNumber1.test(value) ||
        reRealNumber2.test(value) ||
        reNeRealNumber1.test(value) ||
        reNeRealNumber2.test(value)
      ) {
        return true
      } else {
        return false
      }
    } else if (typeof value === 'number') {
      return true
    } else {
      return false
    }
  },
  isEmojiCharacter: function (substring) {
    for (var i = 0; i < substring.length; i++) {
      var hs = substring.charCodeAt(i)
      if (0xd800 <= hs && hs <= 0xdbff) {
        if (substring.length > 1) {
          var ls = substring.charCodeAt(i + 1)
          var uc = (hs - 0xd800) * 0x400 + (ls - 0xdc00) + 0x10000
          if (0x1d000 <= uc && uc <= 0x1f77f) {
            return true
          }
        }
      } else if (substring.length > 1) {
        var ls = substring.charCodeAt(i + 1)
        if (ls == 0x20e3) {
          return true
        }
      } else {
        if (0x2100 <= hs && hs <= 0x27ff) {
          return true
        } else if (0x2b05 <= hs && hs <= 0x2b07) {
          return true
        } else if (0x2934 <= hs && hs <= 0x2935) {
          return true
        } else if (0x3297 <= hs && hs <= 0x3299) {
          return true
        } else if (
          hs == 0xa9 ||
          hs == 0xae ||
          hs == 0x303d ||
          hs == 0x3030 ||
          hs == 0x2b55 ||
          hs == 0x2b1c ||
          hs == 0x2b1b ||
          hs == 0x2b50
        ) {
          return true
        }
      }
    }
  },
  checkPass: function (value) {
    var Regx = /^[0-9a-zA-Z]{8,}/
    var subStr
    for (var i = 0; i < value.length; i++) {
      subStr = value.substring(i, i + 8)
      if (Regx.test(subStr)) {
        return true
      }
    }
  },
}
