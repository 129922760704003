/* eslint-disable */
import { Toast, Dialog } from 'vant'
import Vue from 'vue'

Vue.use(Toast)
Vue.use(Dialog)
export default {
  // 金额格式处理
  formatMoney(s, type) {
    if (/[^0-9\.]/.test(s)) return '0'
    if (s === null || s === '') return '0'
    s = s.toString().replace(/^(\d*)$/, '$1.')
    s = `${s}00`.replace(/(\d*\.\d\d)\d*/, '$1')
    s = s.replace('.', ',')
    const re = /(\d)(\d{3},)/
    while (re.test(s)) s = s.replace(re, '$1,$2')
    s = s.replace(/,(\d\d)$/, '.$1')
    if (type === 0) {
      // 不带小数位(默认是有小数位)
      const a = s.split('.')
      if (a[1] === '00') {
        s = a[0]
      }
    }
    return s
  },
  // 字典值转换
  formatDict(array, value) {
    let result = ''
    array.forEach(item => {
      if (item.value == value) {
        result = item.name
      }
    })
    return result
  },
  isAndroid() {
    return /android/i.test(window.navigator.userAgent)
  },
  isIOS() {
    return /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
  },
  // 提示信息
  toast(msg) {
    Toast(msg)
  },
  // 确认弹框
  dialog() {
    Dialog.alert({
      title: '温馨提示',
    })
  },
  filterPhone(phoneNum) {
    let number = ''
    number = phoneNum.substr(0, 3) + '****' + phoneNum.substr(7)
    return number
  },
}
