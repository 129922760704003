const states = {
  loan: {},
  payInfo: {},
  bankInfo: {},
}
const mutations = {
  loanFn(state, value) {
    state.loan = value
  },

  payInfoFn(state, value) {
    state.payInfo = value
  },

  bankFn(state, value) {
    state.bankInfo = value
  },
}
export default {
  states,
  mutations,
}
