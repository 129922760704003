const borrow = {
  state: {
    loanNo: '', // 借据号
    faceToken: '', // 借款刷脸token
    faceUrl: '', // 借款刷脸url
    creditFaceToken: '', // 授信刷脸token
    creditFaceUrl: '', // 授信刷脸url
    applySeq: '', // 借款申请编号
    creditApplySeqNo: '', // 授信申请编号
    companyInfo: {}, // 企业信息
    merchantRoute: '',
  },
  mutations: {
    SET_LOANNO: (state, val) => {
      state.loanNo = val
    },
    SET_APPLY_SEQ: (state, val) => {
      state.applySeq = val
    },
    SET_CREDIT_APPLY_SEQ_NO: (state, val) => {
      state.creditApplySeqNo = val
    },
    SET_FACE_TOKEN: (state, val) => {
      state.faceToken = val
    },
    SET_FACE_URL: (state, val) => {
      state.faceUrl = val
    },
    SET_CREDIT_FACE_TOKEN: (state, val) => {
      state.creditFaceToken = val
    },
    SET_CREDIT_FACE_URL: (state, val) => {
      state.creditFaceUrl = val
    },
    SET_COMPANY_INFO: (state, value) => {
      state.companyInfo = value
    },
    SET_MERCHANT_ROUTE: (state, value) => {
      state.merchantRoute = value
    },
  },
}

export default borrow
