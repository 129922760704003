const TokenKey = 'Token'
const Uid = 'uid'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}

export function getUid() {
  return localStorage.getItem(Uid)
}

export function setUid(uid) {
  return localStorage.setItem(Uid, uid)
}

export function removeUid() {
  return localStorage.removeItem(Uid)
}
