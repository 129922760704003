import Vue from 'vue'
import { Dialog } from 'vant'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/css/common.less'
import '@/assets/css/normalize.css'
import '@/assets/css/reset.less'
import '@/assets/css/vant.less'
import 'vant/lib/index.css'

import './utils/rem'
import i18n from './lang/index' // 图片压缩

import common from '@/utils/common' // 全局方法
import validate from '@/utils/validate' // 全局校验
import { getToken, setToken } from './utils/auth'
import '@/utils/directive'

// 引用js
require('./utils/lrz.all.bundle.js')

Vue.prototype.$common = common
Vue.prototype.$validate = validate

Vue.config.productionTip = false
Vue.config.devtools = true

// 不需要登录的页面
const whiteList = ['/phoneLogin', '/cgd', '/sfkdd', '/', '/view-contract']

router.beforeEach((to, from, next) => {
  if (Object.keys(to.query).length > 0) {
    store.commit('SET_CHANNEL', JSON.stringify(to.query))
  }
  let { token } = to.query
  if (token) {
    setToken(token)
  } else {
    token = getToken()
  }
  // const token = getToken()
  // if (to.meta.title) {
  //   document.title = to.meta.title
  // }
  // token 不存在 且不在白名单里
  const toLogin = !token && whiteList.indexOf(to.path) === -1
  if (toLogin) {
    Dialog.confirm({
      title: '系统提示',
      message: '登录状态已过期，您可以继续留在该页面，或者重新登录',
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      store.dispatch('LogOut').then(() => {
        location.href = `/phoneLogin?appId=${store.state.common.appId}`
      })
    })
  } else {
    next()
  }
})

router.beforeResolve((to, from, next) => {
  document.title = to.meta.title || '信贷h5'
  next()
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
