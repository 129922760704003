import common from '../common/en'
import borrow from '../borrow/en'
import credit from '../credit/en'
import repayment from '../repayment/en'
import userCenter from '../userCenter/en'

export default {
    common,
    borrow,
    credit,
    repayment,
    userCenter
}