import Cookies from 'js-cookie'
const common = {
  state: {
    language: Cookies.get('language') || 'en',
    isGetQuota: false,
    productCode: '', // 贷款品种
    businessType: '', // 产品类型
    defaultIndex: '', // 首页路径
    appId: '',
  },
  mutations: {
    SET_LANGUAGE: (state, language) => {
      state.language = language
      Cookies.set('language', language)
    },
    SET_GETQUOTA: (state, value) => {
      state.isGetQuota = value
    },
    SET_BUSINESSTYPE: (state, value) => {
      state.businessType = value
    },
    SET_PRODUCT: (state, value) => {
      state.productCode = value
    },
    SET_DEFAULT_INDEX: (state, value) => {
      state.defaultIndex = value
    },
    SET_APPID: (state, value) => {
      state.appId = value
    },
  },

  actions: {
    setLanguage({ commit }, language) {
      commit('SET_LANGUAGE', language)
    },
  },
}

export default common
