import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/store/module/user'
import common from '@/store/module/common'
import repayment from '@/store/module/repayment'
import borrow from '@/store/module/borrow'
import getters from '../store/getter'

import VueXAlong from 'vuex-along'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    common,
    repayment,
    borrow,
    user,
  },
  getters,
  plugins: [
    VueXAlong({
      name: {
        loan: {},
      },
      local: true, //是否存放在local中  false 不存放 如果存放按照下面session的配置配
      //session: { list: [], isFilter: true }, 如果值不为false 那么可以传递对象 其中 当isFilter设置为true时， list 数组中的值就会被过滤调,这些值不会存放在seesion或者local中
    }),
  ],
})
