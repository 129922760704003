export default [
  {
    // 首页
    path: '/cgd',
    name: 'cgd-index',
    component: (resolve) => require(['@/views/cgd-index'], resolve),
    meta: {
      title: '采易融',
    },
  },
  // 账号登录
  {
    path: '/userLogin',
    name: 'userLogin',
    component: (resolve) => require(['@/views/login/userLogin'], resolve),
    meta: {
      title: '登录',
    },
  },
  // 手机验证码登录
  {
    path: '/phoneLogin',
    name: 'phoneLogin',
    component: (resolve) => require(['@/views/login/phoneLogin'], resolve),
    meta: {
      title: '短信验证码登录',
    },
  },
  // 注册
  {
    path: '/register',
    name: 'register',
    component: (resolve) => require(['@/views/login/register'], resolve),
    meta: {
      title: '注册',
    },
  },
  // 授信流程-上传身份证
  {
    path: '/upload-idCard',
    name: 'upload-idCard',
    component: (resolve) => require(['@/views/credit/upload-idCard'], resolve),
    meta: {
      title: '上传身份证',
    },
  },
  // 授信流程-确认身份信息
  {
    path: '/check-info',
    name: 'check-info',
    component: (resolve) => require(['@/views/credit/check-info'], resolve),
    meta: {
      title: '确认身份证信息',
    },
  },
  // 授信流程-企业信息
  {
    path: '/company-info',
    name: 'company-info',
    component: (resolve) => require(['@/views/credit/company-info'], resolve),
    meta: {
      title: '我的企业',
    },
  },
  // 授信流程-确认信息
  {
    path: '/confirm-info',
    name: '/confirm-info',
    component: (resolve) => require(['@/views/credit/confirm-info'], resolve),
    meta: {
      title: '采易融',
    },
  },
  // 授信流程-添加企业信息
  {
    path: '/add-company-info',
    name: 'add-company-info',
    component: (resolve) => require(['@/views/credit/add-company-info'], resolve),
    meta: {
      title: '添加企业信息',
    },
  },
  {
    path: '/face-result',
    name: 'face-result',
    component: (resolve) => require(['@/views/common/face-result'], resolve),
    meta: {
      title: '刷脸结果',
    },
  },
  // 授信流程-选择企业
  {
    path: '/face-recognition',
    name: 'face-recognition',
    component: (resolve) => require(['@/views/borrow/face-recognition'], resolve),
    meta: {
      title: '审核结果',
    },
  },
  {
    path: '/wait-page',
    name: 'wait-page',
    component: (resolve) => require(['@/views/credit/wait-page'], resolve),
    meta: {
      title: '审核结果',
    },
  },
  // 授信流程-人脸识别
  {
    path: '/choose-company',
    name: 'choose-company',
    component: (resolve) => require(['@/views/credit/choose-company'], resolve),
    meta: {
      title: '选择企业',
    },
  },
  // 授信流程-无企业默认状态
  {
    path: '/company-empty',
    name: 'company-empty',
    component: (resolve) => require(['@/views/credit/company-empty'], resolve),
    meta: {
      title: '我的企业',
    },
  },
  // 融资流程-融资信息
  {
    path: '/borrow-info',
    name: 'borrow-info',
    component: (resolve) => require(['@/views/borrow/borrow-info'], resolve),
    meta: {
      title: '完善融资信息',
    },
  },
  // 融资流程-申请信息
  {
    path: '/apply-info',
    name: 'apply-info',
    component: (resolve) => require(['@/views/borrow/apply-info'], resolve),
    meta: {
      title: '完善申请信息',
    },
  },

  // 融资流程-确认融资
  {
    path: '/confirm-borrow',
    name: '/confirm-borrow',
    component: (resolve) => require(['@/views/borrow/confirm-borrow'], resolve),
    meta: {
      title: '确认融资',
    },
  },
  // 融资流程-补充银行卡信息
  {
    path: '/add-back-info',
    name: 'add-back-info',
    component: (resolve) => require(['@/views/borrow/add-back-info'], resolve),
    meta: {
      title: '补充银行卡信息',
    },
  },
  // 融资流程-审核通过状态
  {
    path: '/approvel-pass-result',
    name: 'approvel-pass-result',
    component: (resolve) => require(['@/views/borrow/approvel-pass-result'], resolve),
    meta: {
      title: '审核结果',
    },
  },
  // 融资流程-审核拒绝状态
  {
    path: '/approvel-refuse-result',
    name: 'approvel-refuse-result',
    component: (resolve) => require(['@/views/borrow/approvel-refuse-result'], resolve),
    meta: {
      title: '审核结果',
    },
  },
  // 融资流程-上传发票
  // {
  //   path: '/upload-invoice',
  //   name: 'upload-invoice',
  //   component: resolve => require(['@/views/borrow/upload-invoice'], resolve),
  //   meta: {
  //     title: '上传发票',
  //   },
  // },
  // 融资流程-确认发票信息
  {
    path: '/invoice-info',
    name: 'invoice-info',
    component: (resolve) => require(['@/views/borrow/invoice-info'], resolve),
    meta: {
      title: '确认发票信息',
    },
  },
  // 融资流程-上传凭证
  {
    path: '/upload-certificate',
    name: 'upload-certificate',
    component: (resolve) => require(['@/views/borrow/upload-certificate'], resolve),
    meta: {
      title: '上传凭证',
    },
  },
  // 用户中心-补充银行卡信息
  {
    path: '/change-phone',
    name: 'change-phone',
    component: (resolve) => require(['@/views/user/change-phone'], resolve),
    meta: {
      title: '修改手机号',
    },
  },
  // 404页面
  {
    path: '/notFound',
    name: 'notFound',
    component: (resolve) => require(['@/views/common/notFound'], resolve),
  },
  // 合同查看页面
  {
    path: '/view-contract',
    name: 'view-contract',
    component: (resolve) => require(['@/components/view-contract'], resolve),
    meta: {
      title: '查看合同',
    },
  },
  // 查看pdf
  {
    path: '/viewpdf',
    name: 'viewpdf',
    component: (resolve) => require(['@/views/borrow/viewpdf'], resolve),
    meta: {
      title: '查看合同',
    },
  },
]
