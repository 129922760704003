import { userLoginApi } from '@/apis/credit'
import { getToken, setToken, getUid, setUid, removeToken, removeUid } from '@/utils/auth'

// import { getCodeImg } from '@/utils/tools'
// import { getToken, setToken, , getUid, setUid, removeUid } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    uid: getUid(),
    expire: '',
    channel: {
      subChannel: '',
      inChannel: '',
      applyChannel: '',
    },
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },

    SET_UID: (state, uid) => {
      state.uid = uid
    },

    REMOVE_TOKEN: state => {
      state.token = ''
    },

    REMOVE_UID: state => {
      state.uid = ''
    },
    SET_CHANNEL: (state, channel) => {
      state.channel = JSON.parse(channel)
    },
  },
  actions: {
    Login({ commit }, loginInfo) {
      return new Promise((resolve, reject) => {
        userLoginApi(loginInfo)
          .then(res => {
            const { data } = res
            setToken(data.token)
            setUid(data.uid)
            commit('SET_TOKEN', data.token)
            commit('SET_UID', data.uid)
            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    LogOut({ commit }) {
      return new Promise(resolve => {
        removeToken()
        removeUid()
        commit('SET_GETQUOTA', false)
        commit('REMOVE_TOKEN')
        commit('REMOVE_UID')
        commit('SET_BUSINESSTYPE', '')
        resolve()
      })
    },
  },
}

export default user
