export default [
  // 付款列表
  {
    path: '/repaymentList',
    name: 'repaymentList',
    component: (resolve) => require(['@/views/repayment/repaymentList'], resolve),
    beforeEnter: (to, from, next) => {
      if (to.query.type == 'UnsetlLoan') {
        to.meta.title = '我的付款'
      } else {
        to.meta.title = '我的融资'
      }
      next()
    },
  },
  // 我的付款信息
  {
    path: '/repaymentDetail',
    name: 'repaymentDetail',
    component: (resolve) => require(['@/views/repayment/repaymentDetail'], resolve),
    meta: {
      title: '我的融资',
    },
  },
  // 付款支付
  {
    path: '/repaymentPay',
    name: 'repaymentPay',
    component: (resolve) => require(['@/views/repayment/repaymentPay'], resolve),
    meta: {
      title: '付款支付',
    },
  },
  // 付款结果
  {
    path: '/repaymentResult',
    name: 'repaymentResult',
    component: (resolve) => require(['@/views/repayment/repaymentResult'], resolve),
    meta: {
      title: '付款结果',
    },
  },
  // 融资信息-已结清
  {
    path: '/repaymentInfo',
    name: 'repaymentInfo',
    component: (resolve) => require(['@/views/repayment/repaymentInfo'], resolve),
    meta: {
      title: '融资信息',
    },
  },
  // 转账付款
  {
    path: '/repaymentTransfer',
    name: 'repaymentTransfer',
    component: (resolve) => require(['@/views/repayment/repaymentTransfer'], resolve),
    meta: {
      title: '转账付款',
    },
  },
  // 用户中心
  {
    path: '/userCenter',
    name: 'userCenter',
    component: (resolve) => require(['@/views/user/userCenter'], resolve),
    meta: {
      title: '账户设置',
    },
  },
  // 个人主页
  {
    path: '/home',
    name: 'home',
    component: (resolve) => require(['@/views/user/home'], resolve),
    meta: {
      title: '个人主页',
    },
  },
  // 身份验证
  {
    path: '/verify',
    name: 'verify',
    component: (resolve) => require(['@/views/user/verify'], resolve),
    meta: {
      title: '修改手机号码',
    },
  },
  // 重置手机号
  {
    path: '/resetPhone',
    name: 'resetPhone',
    component: (resolve) => require(['@/views/user/resetPhone'], resolve),
    meta: {
      title: '修改手机号码',
    },
  },
  // 重置成功
  {
    path: '/success',
    name: 'success',
    component: (resolve) => require(['@/views/user/success'], resolve),
    meta: {
      title: '操作成功',
    },
  },
  // 公司信息
  {
    path: '/componyInfo',
    name: 'componyInfo',
    component: (resolve) => require(['@/views/user/componyInfo'], resolve),
    meta: {
      title: '我的企业',
    },
  },
  // 更新身份证信息
  {
    path: '/idCardInfo',
    name: 'idCardInfo',
    component: (resolve) => require(['@/views/user/idCardInfo'], resolve),
    meta: {
      title: '更新身份证信息',
    },
  },
  // 修改银行卡
  {
    path: '/changeBankCard',
    name: 'changeBankCard',
    component: (resolve) => require(['@/views/user/changeBankCard'], resolve),
    meta: {
      title: '修改银行卡',
    },
  },

  // 合同列表
  {
    path: '/repayContract',
    name: 'repayContract',
    component: (resolve) => require(['@/views/repayment/repayContract'], resolve),
    meta: {
      title: '合同列表',
    },
  },

  // 合同详情
  {
    path: '/reviewContract',
    name: 'reviewContract',
    component: (resolve) => require(['@/views/repayment/reviewContract'], resolve),
    meta: {
      title: '合同列表',
    },
  },
]
