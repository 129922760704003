import common from '../common/zh'
import borrow from '../borrow/zh'
import credit from '../credit/zh'
import repayment from '../repayment/zh'
import userCenter from '../userCenter/zh'

export default {
    common,
    borrow,
    credit,
    repayment,
    userCenter
}