import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import enLocale from './lib/en'
import zhLocale from './lib/zh'
Vue.use(VueI18n)

const messages = {
    en: {
        ...enLocale,
    },
    zh: {
        ...zhLocale,
    }
}

// 初始化VueI18n
const i18n = new VueI18n({
    // this.$i18n.locale // 通过切换locale的值来实现语言切换
    locale: Cookies.get('language') || 'zh',
    messages
})

export default i18n