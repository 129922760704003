import Vue from 'vue'
// 防抖
Vue.directive('debounce', {
  inserted: function(el, binding) {
    let timer
    el.addEventListener('click', () => {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        binding.value()
      }, 1000)
    })
  },
})
// 节流
Vue.directive('throttle', {
  inserted: function(el, binding) {
    let [fn, event = 'click', time = 300] = binding.value
    let timer, timer_end
    el.addEventListener(event, () => {
      if (timer) {
        clearTimeout(timer_end)
        return (timer_end = setTimeout(() => fn(), time))
      }
      fn()
      timer = setTimeout(() => (timer = null), time)
    })
  },
})
