import request from '@/utils/request'
import { Encrypt } from '@/utils/crypto'
// 获取验证码
export function getSendCodeApi(data) {
  data.mobile = Encrypt(data.mobile)
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/loginAndRegister/sendCode',
    data,
  })
}
// 短信验证码登录
export function userLoginApi(data) {
  data.mobile = Encrypt(data.mobile)
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/loginAndRegister/userLogin',
    data,
  })
}
// 查询银行卡列表
export function getBackListApi(data) {
  return request({
    method: 'post',
    url: `/cls/passport-personal/app/common/bankList`,
    data,
  })
}
// 根据业务类型获取额度信息
export function getLimitVarietyApi(data) {
  return request({
    method: 'get',
    url: '/cls/credit/app/home/getLimitVariety',
    params: data,
  })
}
// 查询用户所属企业信息
export function userCompanyListApi() {
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/user/info/userCompanyList',
  })
}

// OCR上传并解析身份证
export function OcrIdCardApi(data) {
  // uploadApi.fileUpload('/cls/passport-personal/app/common/OcrIdCard', data)
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/common/OcrIdCard',
    data,
  })
}

// OCR上传并解析营业执照
// export function parseLicenseInfoApi(data, success) {
//   uploadApi.fileUpload('/cls/passport-personal/app/user/info/parseLicenseInfo', data, success)
// }
// 添加企业信息
export function addEnterpriseInfoApi(data) {
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/user/info/addEnterpriseInfo',
    data,
  })
}
// 三要素短信
export function sendCodeByThreeBasicApi(data) {
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/common/SendCodeByThreeBasic',
    data,
  })
}
// 三要素短信认证
export function confirmByThreeBasicApi(data) {
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/common/ConfirmByThreeBasic',
    data,
  })
}
// 超霸二要素认证
export function CBAuthByTwoBasicApi(data) {
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/common/CBAuthByTwoBasic',
    data,
  })
}
// 预授信
export function creditPreApi(data) {
  return request({
    method: 'post',
    url: '/cls/credit/app/credit/pre',
    data,
  })
}
// 获取刷脸token
export function getFaceTokenApi(data) {
  return request({
    method: 'post',
    url: '/cls/credit/app/creditFace/faceToken',
    data,
  })
}
// 获取刷脸url
export function getScanFaceUrlApi(data) {
  return request({
    method: 'post',
    url: `/cls/authentication/app/auth/face/getScanFaceUrl/${data}`,
  })
}
// 刷脸结果查询
export function getfaceResultApi(data) {
  return request({
    method: 'post',
    url: `/cls/credit/app/creditFace/faceResult`,
    data,
  })
}
// 根据授信路由获取合同模板
export function getTempByApplyInfoApi(data) {
  return request({
    method: 'post',
    url: `/cls/credit/app/credit/getTempByApplyInfo`,
    data,
  })
}
// 授信申请
export function creditApplyApi(data) {
  return request({
    method: 'post',
    url: `/cls/credit/app/credit/apply`,
    data,
  })
}
// 获取查看合同的tempCode
export function getTempCodeApi(data) {
  return request({
    method: 'post',
    url: `/cls/core/app/product/getTempCode`,
    data,
  })
}
// 获取授权协议
export function getCreditAgreementApi(data) {
  return request({
    method: 'post',
    url: `/cls/credit/app/home/getCreditAgreement`,
    params: data,
  })
}
// 查询已预授信的信息
export function getApplyConfirmInfoApi(data) {
  return request({
    method: 'post',
    url: `/cls/credit/app/credit/applyConfirmInfo`,
    data,
  })
}
